import { collection } from "firebase/firestore";
import { db } from "../firebase";

import { onSnapshot } from "firebase/firestore";

export const fetchCliniques = (callback) => {
    try {
        const cliniquesCollectionRef = collection(db, "veto");
        const unsubscribe = onSnapshot(cliniquesCollectionRef, (cliniquesSnapshot) => {
            const cliniquesList = cliniquesSnapshot.docs.map(doc => ({
                userUID: doc.id,
                ...doc.data()
            }));
            callback(cliniquesList);
        });
        return unsubscribe;
    } catch (error) {
        console.error("Erreur lors de la récupération de la liste des cliniques:", error);
        callback([]);
    }
};
