import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, Grid, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { auth, db } from './firebase';
import { AddDemandes } from './demandes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CremaCard } from './views/cremaCard';

const Accueil = ({ userRole, userEmail }) => {
  const [demandes, setDemandes] = useState([]);
  const [viewDemande, setViewDemande] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vetoUsers, setVetoUsers] = useState([]);

  useEffect(() => {
    const fetchUserData = async (uid) => {
      try {
        if (uid) {
          const userDocRef = doc(db, "veto", uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            return userDocSnap.data();
          } else {
            return null;
          }
        }
        else return null
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
        return null;
      }
    };

    const fetchVetoUsers = async () => {
      if (userRole === 'admin') {
        try {
          const vetoCollectionRef = collection(db, "veto");
          const q = query(vetoCollectionRef, where("profile", "==", "veterinaire"));
          const vetoSnapshot = await getDocs(q);
          const vetoData = vetoSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setVetoUsers(vetoData);
        } catch (error) {
          console.error("Erreur lors de la récupération des utilisateurs veto:", error);
        }
      }
    };

    const fetchDemandes = (userRole, setDemandes, setLoading) => {
      setLoading(true);
      let unsubscribe;
      try {
        let demandesCollectionRef;
        if (auth.currentUser) {
          if (userRole === 'admin') {
            demandesCollectionRef = collection(db, "demandes");
          } else {
            demandesCollectionRef = query(collection(db, "demandes"), where("UID", "==", auth.currentUser.uid));
          }

          unsubscribe = onSnapshot(demandesCollectionRef, async (snapshot) => {
            const demandesData = snapshot.docs.map(async (doc) => {
              const demandeData = { id: doc.id, ...doc.data() };
              if (userRole === 'admin') {
                const userData = await fetchUserData(demandeData.UID);
              }
              return demandeData;
            });

            const demandesDataResolved = await Promise.all(demandesData);
            demandesDataResolved.sort((a, b) => b.created - a.created);
            const data = [...demandesDataResolved.filter(demande => demande.deleted !== true)];
            setDemandes(data);
            setLoading(false);
          });
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des demandes:", error);
        setLoading(false);
      }

      return unsubscribe;
    };
    const unsubscribeDemandes = fetchDemandes(userRole, setDemandes, setLoading);
    fetchVetoUsers();

    return () => {
      if (unsubscribeDemandes) {
        unsubscribeDemandes();
      }
    };
  }, [userRole]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderDemandeCard = (demande) => {

    const handleEdit = (demande) => {
      setViewDemande(demande);
      handleOpenModal();
    };

    return (
      <CremaCard
        demande={demande}
        handleEdit={handleEdit}
      />
    );
  };
  return (
    <Container maxWidth="lg" sx={{ paddingBottom: 15 }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {userRole === 'admin' && (
            <Box sx={{ marginTop: 4 }}>
              <Typography variant="h5" align="center" gutterBottom>
                Cliniques Vétérinaires
              </Typography>
              {vetoUsers.filter(veto => demandes.some(demande => demande.UID === veto.id)).map((veto) => (
                <Accordion key={veto.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{veto.clinique} - {veto.ville}</Typography>
                    {demandes.some(demande => demande.UID === veto.id && demande.statut === 'Nouvelle demande') && (
                      <span style={{
                        display: 'inline-block',
                        width: '8px',
                        height: '8px',
                        backgroundColor: '#FB6D48',
                        // borderRadius: '10%',
                        marginLeft: '8px'
                      }}></span>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10 }}>
                      {
                        demandes
                          .filter(demande => demande.UID === veto.id)
                          .sort((a, b) => {
                            if (a.statut === 'Nouvelle demande' && b.statut !== 'Nouvelle demande') {
                              return -1;
                            }
                            if (a.statut !== 'Nouvelle demande' && b.statut === 'Nouvelle demande') {
                              return 1;
                            }
                            return 0;
                          })
                          .map((demande) => renderDemandeCard(demande))
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
          {userRole !== 'admin' && (
            demandes.length === 0 ? (
              <Typography variant="h6" align="center" sx={{ marginTop: 4 }}>
                Aucune demande disponible
              </Typography>
            ) : (
              <Box>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Demandes en cours</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3} justifyContent="center" sx={{  }}>
                      {demandes
                        .filter(demande => demande.statut !== 'Crémation effectuée')
                        .map(demande => renderDemandeCard(demande))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {demandes.some(demande => demande.statut === 'Crémation effectuée') && (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Crémations effectuées</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10 }}>
                      {demandes
                        .filter(demande => demande.statut === 'Crémation effectuée')
                        .map(demande => renderDemandeCard(demande))}
                    </Grid>
                  </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            )
          )}
        </>
      )}

      <AddDemandes demandeProp={viewDemande} open={openModal} isEditMode={false} userRole={userRole} onClose={handleCloseModal} userEmail={userEmail} />
    </Container>
  );
};

export default Accueil;
