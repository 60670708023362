import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, addDoc, updateDoc, doc, getDoc, getDocs, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAlert } from './AlertContext'; // Remplacez par la bibliothèque d'alertes que vous utilisez
import { auth, db, storage } from './firebase';
import { Container, Typography, Dialog, DialogTitle, DialogContent, IconButton, Box, Grid, FormControl, InputLabel, Select, MenuItem, Button, DialogActions, Card, CardContent } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { localeText } from './config/localeText';
import { handleSendEmail } from './sendMail';
import EmailIcon from '@mui/icons-material/Email';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const GetInvoices = (userRole, onUpdateInvoices) => {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [viewInvoice, setViewInvoice] = useState(null);
    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const [emailContent, setEmailContent] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const { showAlert } = useAlert();

    const fetchUserData = async (uid) => {
        try {
            if (uid) {
                const userDocRef = doc(db, "veto", uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    return userDocSnap.data();
                } else {
                    return null;
                }
            }
            else return null
        } catch (error) {
            console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
            return null;
        }
    };

    const fetchInvoices = () => {
        setLoading(true);
        const userUID = auth.currentUser.uid;
        let invoicesCollectionRef = collection(db, "invoices");

        if (userRole.userRole !== 'admin') {
            invoicesCollectionRef = query(invoicesCollectionRef, where('userUID', '==', userUID));
        }

        const unsubscribe = onSnapshot(invoicesCollectionRef, async (snapshot) => {
            const data = await Promise.all(snapshot.docs.map(async doc => {
                const invoiceData = doc.data();
                let clinique = '';
                let userEmail = '';

                // Utiliser fetchUserData pour récupérer le nom de la clinique
                if (invoiceData.userUID) {
                    const userData = await fetchUserData(invoiceData.userUID);
                    if (userData) {
                        clinique = userData.clinique;
                        userEmail = userData.email;
                    }
                }
                return { id: doc.id, ...invoiceData, clinique, userEmail };
            }));
            setInvoices(data);
            setLoading(false);
        }, (error) => {
            console.error("Erreur lors de la récupération des factures:", error);
            setLoading(false);
        });

        return unsubscribe;
    };

    useEffect(() => {
        const unsubscribe = fetchInvoices();
        return () => unsubscribe && unsubscribe();
    }, []);


    const handleDeleteInvoice = async (id) => {
        await deleteDoc(doc(db, "invoices", id));
        showAlert("Facture supprimée avec succès !", 'success');
    };

    const handleSendInvoiceEmail = async () => {
        setOpenEmailDialog(false);
        const send = await handleSendEmail(
            userEmail, // Adresse email de l'utilisateur
            'accueil@crematorium-animalierdepicardie.fr', // Adresse email de l'expéditeur
            'Facture ' + viewInvoice.month + ' ' + viewInvoice.year, // Sujet de l'email
            emailContent,
            viewInvoice.fileURL
        );
        if (send.message === "Email sent successfully via Brevo") {
            showAlert("Email envoyé avec succès !", 'success');
        } else {
            showAlert("Erreur lors de l'envoi de l'email !", 'error');
        }
    };

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setViewInvoice(null);
        setEmailContent('');
    };

    const handleOpenEmailDialog = (email, invoice) => {
        setViewInvoice(invoice);
        setOpenEmailDialog(true);
        setUserEmail(email);
        setEmailContent('Bonjour,<br />Vous trouverez ci-joint la facture suivante :<br />Période : <b>' + invoice.month + ' ' + invoice.year + '</b>.<br />Cordialement,<br />L\'équipe du Crématorium Animalier de Picardie');
    };

    // Fonction pour fermer la boîte de dialogue d'envoi d'email
    const handleCloseEmailDialog = () => {
        setOpenEmailDialog(false);
    };

    return (
        <>
            <Container maxWidth="lg">
                <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Factures</Typography>
                {userRole.userRole === 'admin' && (
                    <IconButton
                        color="primary"
                        aria-label="add"
                        onClick={() => setOpenAddModal(true)}
                    // sx={{ position: 'fixed', bottom: 16, right: 16 }}
                    >
                        <AddIcon />
                    </IconButton>
                )}
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box sx={{ marginTop: 4 }}>
                        {/* Grouper par clinique */}
                    {invoices.length === 0 ? (
                        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                            Aucune facture disponible
                        </Typography>
                    ) : (
                        Array.from(new Set(invoices.map(invoice => invoice.clinique))) // Obtenir la liste unique des cliniques
                            .map((clinique) => (
                                <Accordion key={clinique} defaultExpanded={userRole.userRole !== 'admin'}>
                                    {userRole.userRole === 'admin' && (
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            sx={{
                                                backgroundColor: '#f5f5f5',
                                                '&:hover': { backgroundColor: '#e0e0e0' }
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <Typography>{clinique}</Typography>
                                                <Typography variant="caption" sx={{ marginLeft: 2, color: 'text.secondary' }}>
                                                    ({invoices.filter(invoice => invoice.clinique === clinique).length} factures)
                                                </Typography>
                                            </Box>
                                        </AccordionSummary>
                                    )}
                                    <AccordionDetails>
                                        {/* Grouper par année */}
                                        {Object.entries(
                                            invoices
                                                .filter(invoice => invoice.clinique === clinique)
                                                .reduce((acc, invoice) => {
                                                    if (!acc[invoice.year]) {
                                                        acc[invoice.year] = [];
                                                    }
                                                    acc[invoice.year].push(invoice);
                                                    return acc;
                                                }, {})
                                        )
                                            .sort((a, b) => b[0] - a[0]) // Tri décroissant des années
                                            .map(([year, yearInvoices]) => {
                                                const currentYear = new Date().getFullYear().toString();
                                                const isCurrentYear = year === currentYear;

                                                return (
                                                    <Accordion
                                                        key={year}
                                                        sx={{ backgroundColor: '#f8f8f8' }}
                                                        defaultExpanded={isCurrentYear}
                                                    >
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <Typography>
                                                                    {year}
                                                                </Typography>
                                                                <Typography variant="caption" sx={{ marginLeft: 2, color: 'text.secondary' }}>
                                                                    ({yearInvoices.length} factures)
                                                                </Typography>
                                                            </Box>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={3}>
                                                                {yearInvoices
                                                                    .sort((a, b) => new Date(b.created) - new Date(a.created))
                                                                    .map((invoice) => (
                                                                        <Grid item xs={12} sm={6} md={4} lg={3} key={invoice.id}>
                                                                            <Card
                                                                                sx={{
                                                                                    cursor: 'pointer',
                                                                                    height: '100%',
                                                                                    transition: 'transform 0.2s',
                                                                                    '&:hover': {
                                                                                        transform: 'scale(1.02)'
                                                                                    }
                                                                                }}
                                                                                onClick={() => window.open(invoice.fileURL, '_blank')}
                                                                            >
                                                                                <CardContent>
                                                                                    <Box sx={{
                                                                                        backgroundColor: '#1976D2',
                                                                                        color: 'white',
                                                                                        padding: '10px',
                                                                                        marginBottom: 2,
                                                                                        borderRadius: '4px',
                                                                                        display: 'flex',
                                                                                        justifyContent: 'space-between',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                                                                            <Typography variant="h6" sx={{ textAlign: 'center', flexGrow: 1 }}>
                                                                                                {invoice.month}
                                                                                            </Typography>
                                                                                            {userRole.userRole === 'admin' && (
                                                                                                <Box>
                                                                                                    <IconButton
                                                                                                        size="small"
                                                                                                        color="inherit"
                                                                                                        onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                            handleOpenEmailDialog(invoice.userEmail, invoice);
                                                                                                        }}
                                                                                                    >
                                                                                                        <EmailIcon />
                                                                                                    </IconButton>
                                                                                                    <IconButton
                                                                                                        size="small"
                                                                                                        color="inherit"
                                                                                                        onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                            handleDeleteInvoice(invoice.id);
                                                                                                        }}
                                                                                                    >
                                                                                                        <DeleteIcon />
                                                                                                    </IconButton>
                                                                                                </Box>
                                                                                            )}
                                                                                        </Box>
                                                                                    </Box>
                                                                                    <Typography variant="body2" color="text.secondary">
                                                                                        <strong>Date de création:</strong> {new Date(invoice.created).toLocaleDateString()}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </Card>
                                                                        </Grid>
                                                                    ))}
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                );
                                            })}
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        )}
                    </Box>
                )}
                <AddInvoice invoiceProp={viewInvoice} userRole={userRole} onClose={handleCloseAddModal} open={openAddModal} onUpdateInvoices={onUpdateInvoices} />

            </Container>
            <Dialog open={openEmailDialog} onClose={handleCloseEmailDialog}>
                <DialogTitle>Envoyer un Email</DialogTitle>
                <DialogContent>
                    <ReactQuill
                        value={emailContent}
                        onChange={setEmailContent}
                        modules={{
                            toolbar: [
                                [{ 'header': [1, 2, false] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                ['link', 'image'],
                                ['clean']
                            ],
                        }}
                        formats={[
                            'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'indent',
                            'link', 'image'
                        ]}
                        style={{ height: '300px', marginBottom: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEmailDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleSendInvoiceEmail} color="primary">
                        Envoyer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const AddInvoice = ({ invoiceProp, userRole, onClose, open, onUpdateInvoices }) => {
    const [invoice, setInvoice] = useState({
        file: null,
        clinique: '',
        month: '',
    });
    const [clinics, setClinics] = useState([]);
    const [userEmail, setUserEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const { showAlert } = useAlert();

    useEffect(() => {
        if (invoiceProp) {
            setInvoice(invoiceProp);
        } else {
            setInvoice({ file: null, clinique: '', month: '' });
        }
    }, [invoiceProp]);

    useEffect(() => {
        const fetchClinics = async () => {
            const clinicsData = await fetchClinicsData();
            setClinics(clinicsData);
        };
        fetchClinics();
    }, []);

    const fetchClinicsData = async () => {
        try {
            const vetoCollectionRef = collection(db, "veto");
            const vetoSnapshot = await getDocs(vetoCollectionRef);
            if (!vetoSnapshot.empty) {
                return vetoSnapshot.docs.map(doc => ({
                    clinique: doc.data().clinique,
                    userUID: doc.id
                }));
            } else {
                return [];
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des cliniques:", error);
            return [];
        }
    };


    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'clinique') {
            const selectedClinic = clinics.find(clinic => clinic.clinique === value);
            setInvoice(prev => ({
                ...prev,
                clinique: value,
                userUID: selectedClinic ? selectedClinic.userUID : ''
            }));
        } else {
            setInvoice(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setInvoice(prev => ({
                ...prev,
                file: file
            }));
        } else {
            showAlert("Veuillez sélectionner un fichier.", 'warning');
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const fileRef = ref(storage, `invoices/${invoice.userUID}/${invoice.file.name}`);
            await uploadBytes(fileRef, invoice.file);
            const fileURL = await getDownloadURL(fileRef);

            const invoiceData = {
                clinique: invoice.clinique,
                month: invoice.month,
                fileURL: fileURL,
                userUID: invoice.userUID,
                created: new Date().toISOString(),
                year: invoice.year
            };
            if (invoice.id) {
                await updateDoc(doc(db, "invoices", invoice.id), invoiceData);
                showAlert("Facture mise à jour avec succès !", 'success');
            } else {
                await addDoc(collection(db, "invoices"), invoiceData);
                showAlert("Nouvelle facture créée avec succès !", 'success');
            }
            onClose();
        } catch (error) {
            console.error("Erreur lors de la soumission de la facture :", error);
            showAlert("Erreur lors de la soumission.", 'error');
        } finally {
            setLoading(false); // Fin du chargement
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{invoice.id ? "Modifier la facture" : "Nouvelle facture"}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Container>
                    <Box component="form" onSubmit={handleFormSubmit}>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel>Clinique</InputLabel>
                                <Select
                                    name="clinique"
                                    value={invoice.clinique}
                                    onChange={handleChange}
                                    required
                                    label="Clinique"
                                >
                                    {clinics.map(clinique => (
                                        <MenuItem key={clinique.userUID} value={clinique.clinique}>
                                            {clinique.clinique}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel>Mois</InputLabel>
                                <Select
                                    name="month"
                                    value={invoice.month}
                                    onChange={handleChange}
                                    required
                                    label="Mois"
                                >
                                    {['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'].map(month => (
                                        <MenuItem key={month} value={month}>
                                            {month}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel>Année</InputLabel>
                                <Select
                                    name="year"
                                    value={invoice.year}
                                    onChange={handleChange}
                                    required
                                    label="Année"
                                >
                                    {Array.from({ length: 5 }, (_, i) => (new Date().getFullYear() + i)).map(year => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <input
                                    type="file"
                                    accept="application/pdf"
                                    onChange={handleFileChange}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <Button
                                    type='submit'
                                    variant="contained"
                                    color="success"
                                    startIcon={loading ? <CircularProgress size={24} /> : <SaveIcon sx={{ fontSize: "2rem" }} />}
                                    sx={{ fontSize: "1rem", padding: "10px 20px" }}
                                    disabled={loading} // Désactiver le bouton pendant le chargement
                                >
                                    Enregistrer
                                </Button>
                            </FormControl>
                        </Grid>
                    </Box>
                </Container>
            </DialogContent>
        </Dialog>
    );
};