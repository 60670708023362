export const localeText = {
    // Pagination
    MuiTablePagination: {
        labelRowsPerPage: 'Lignes par page',
        labelDisplayedRows: ({ from, to, count }) => `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`,
    },
    noRowsLabel: 'Aucune ligne',
    noResultsOverlayLabel: 'Aucun résultat trouvé.',
    errorOverlayDefaultLabel: 'Une erreur est survenue.',
    toolbarDensity: 'Densité',
    toolbarDensityLabel: 'Densité',
    toolbarDensityCompact: 'Compacte',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Confortable',
    toolbarColumns: 'Colonnes',
    toolbarColumnsLabel: 'Sélectionner les colonnes',
    toolbarFilters: 'Filtres',
    toolbarFiltersLabel: 'Afficher les filtres',
    toolbarFiltersTooltipHide: 'Masquer les filtres',
    toolbarFiltersTooltipShow: 'Afficher les filtres',
    toolbarExport: 'Exporter',
    toolbarExportLabel: 'Exporter',
    toolbarExportCSV: 'Télécharger en CSV',
    toolbarExportPrint: 'Imprimer',
    columnsPanelTextFieldLabel: 'Trouver une colonne',
    columnsPanelTextFieldPlaceholder: 'Titre de la colonne',
    columnsPanelDragIconLabel: 'Réorganiser la colonne',
    columnsPanelShowAllButton: 'Afficher tout',
    columnsPanelHideAllButton: 'Masquer tout',
    filterPanelAddFilter: 'Ajouter un filtre',
    filterPanelDeleteIconLabel: 'Supprimer',
    filterPanelLinkOperator: 'Opérateur logique',
    filterPanelOperators: 'Opérateurs',
    filterPanelOperatorAnd: 'Et',
    filterPanelOperatorOr: 'Ou',
    filterPanelColumns: 'Colonnes',
    filterPanelInputLabel: 'Valeur',
    filterPanelInputPlaceholder: 'Filtrer la valeur',
    filterOperatorContains: 'contient',
    filterOperatorNotContains: 'ne contient pas',
    filterOperatorEquals: 'est égal à',
    filterOperatorNotEquals: 'n\'est pas égal à',
    filterOperatorStartsWith: 'commence par',
    filterOperatorEndsWith: 'finit par',
    filterOperatorIs: 'est',
    filterOperatorNot: 'n\'est pas',
    filterOperatorAfter: 'est après',
    filterOperatorOnOrAfter: 'est le ou après',
    filterOperatorBefore: 'est avant',
    filterOperatorOnOrBefore: 'est le ou avant',
    filterOperatorIsEmpty: 'est vide',
    filterOperatorIsNotEmpty: 'n\'est pas vide',
    filterOperatorIsAnyOf: 'est l\'un de',
    filterValueAny: 'n\'importe lequel',
    filterValueTrue: 'vrai',
    filterValueFalse: 'faux',
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Afficher les colonnes',
    columnMenuFilter: 'Filtrer',
    columnMenuHideColumn: 'Masquer',
    columnMenuUnsort: 'Désactiver le tri',
    columnMenuSortAsc: 'Trier par ordre croissant',
    columnMenuSortDesc: 'Trier par ordre décroissant',
    columnHeaderFiltersTooltipActive: (count) => count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`,
    columnHeaderFiltersLabel: 'Afficher les filtres',
    columnHeaderSortIconLabel: 'Trier',
    footerRowSelected: (count) => count !== 1 ? `${count.toLocaleString()} lignes sélectionnées` : ``,
    footerTotalRows: 'Lignes totales:',
    footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} sur ${totalCount.toLocaleString()}`,
    checkboxSelectionHeaderName: 'Sélection',
    booleanCellTrueLabel: 'vrai',
    booleanCellFalseLabel: 'faux',
    actionsCellMore: 'plus',
    pinToLeft: 'Épingler à gauche',
    pinToRight: 'Épingler à droite',
    unpin: 'Désépingler',
    treeDataGroupingHeaderName: 'Groupe',
    treeDataExpand: 'voir les enfants',
    treeDataCollapse: 'masquer les enfants',
    groupingColumnHeaderName: 'Groupe',
    groupColumn: (name) => `Grouper par ${name}`,
    expandDetailPanel: 'Développer',
    collapseDetailPanel: 'Réduire',
    // Ajout des traductions manquantes
    columnMenuManageColumns: 'Gérer les colonnes',
    columnMenuShowColumns: 'Afficher les colonnes',
    columnMenuFilter: 'Filtrer',
    columnMenuHideColumn: 'Masquer',
    columnMenuUnsort: 'Désactiver le tri',
    columnMenuSortAsc: 'Trier par ordre croissant',
    columnMenuSortDesc: 'Trier par ordre décroissant',
};