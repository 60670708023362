import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

export const fetchUserData = async (uid) => {
    try {
        if (uid) {
            const userDocRef = doc(db, "veto", uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                return userDocSnap.data();
            } else {
                return null;
            }
        }
        else return null
    } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
        return null;
    }
};