import React, {useState} from 'react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from './firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import { TextField, Button, Typography, Container, Box, CircularProgress } from '@mui/material';
import  logo  from './img/logo1.png';
 
const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
      e.preventDefault(); // Empêche le formulaire de se soumettre de manière traditionnelle
      setLoading(true);
      try {
          await signInWithEmailAndPassword(auth, email, password);
          console.log("Vous êtes connecté !");
      } catch (error) {
          setError(error.message);
          console.error("Erreur de connexion: ", error.message);
      }
      setLoading(false);
  };

  return (
      <Container component="main" maxWidth="xs">
          <Box
              sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
              }}
          >
                <img src={logo} alt="My Image" style={{ width: '100%', maxWidth: '300px' }} />
              {/* <Typography component="h1" variant="h5">
                  Crématorium Animalier de Picardie
              </Typography> */}
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Mot de passe"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  {error && <Typography color="error">{error}</Typography>}
                  <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={loading}
                  > 
                      {loading ? <CircularProgress size={24} /> : "Se connecter"}
                  </Button>
              </Box>
          </Box>
      </Container>
  );
};

export default LoginForm;
