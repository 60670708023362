import { Box, Typography, Divider, Grid } from '@mui/material';
import dayjs from 'dayjs';

export const DemandeLecture = ({ demande }) => {
    const renderField = (label, value) => (
        <Typography variant="body1" sx={{ marginBottom: 0.5 }}>
            <span style={{ fontSize: 12 }}>{label}: </span><span style={{ fontWeight: 'bold', fontSize: 13 }}>{value}</span>
        </Typography>
    );

    return (
        <Box sx={{ padding: 2 }} id="demande-lecture">
            <Grid container spacing={2} alignItems="flex-start">
                {/* Colonne 1 : Informations sur l'animal et le propriétaire */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ backgroundColor: '#e0e0e0', padding: 1, borderRadius: 2 }}>
                        {/* Section Informations sur l'animal */}
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1, fontSize: 17 }}>
                            Informations sur l'animal
                        </Typography>
                        <Divider sx={{ marginBottom: 2 }} />
                        {renderField("Nom de l'animal", demande.animalName)}
                        {renderField("Type de l'animal", demande.animalType)}
                        {renderField("Race de l'animal", demande.animalRace)}
                        {renderField("Poids de l'animal", demande.animalWeight ? demande.animalWeight + ' kg' : '')}
                    </Box>

                    <Box sx={{ backgroundColor: '#e0e0e0', padding: 1, borderRadius: 2, marginTop: 3 }}>
                        {/* Section Informations sur la crémation */}
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1, fontSize: 17 }} id='cremation-section'>
                            Informations sur la crémation
                        </Typography>
                        <Divider sx={{ marginBottom: 2 }} />
                        {renderField("Type de crémation", demande.cremationType)}
                        {renderField("Urne", demande.urne ? 'Oui' : 'Non')}
                        {demande.urne && renderField("Type d'urne", demande.urneType)}
                        {renderField("Pierre éternelle", demande.pierreEternelle ? 'Oui' : 'Non')}
                        {demande.pierreEternelle && renderField("Valeur de la pierre éternelle", demande.pierreEternelleValue)}
                        {/* {demande.pierreEternelle && renderField("Type de pierre éternelle", demande.pierreEternelleType)} */}
                    </Box>

                </Grid>

                {/* Colonne 2 : Informations sur la crémation et autres informations */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ backgroundColor: '#e0e0e0', padding: 1, borderRadius: 2 }}>
                        {/* Section Informations sur le propriétaire */}
                        {demande.ownerFirstName && demande.ownerLastName && (
                            <>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1, fontSize: 17 }} id='proprietaire-section'>
                                    Informations sur le propriétaire
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />
                                {renderField("Prénom", demande.ownerFirstName)}
                                {renderField("Nom", demande.ownerLastName)}
                                {renderField("Adresse", demande.ownerAddress)}
                                {renderField("Code postal", demande.ownerPostalCode)}
                                {renderField("Ville", demande.ownerCity)}
                                {renderField("Téléphone", demande.ownerPhone)}
                            </>
                        )}
                    </Box>

                    <Box sx={{ backgroundColor: '#e0e0e0', padding: 1, borderRadius: 2, marginTop: demande.ownerFirstName && demande.ownerLastName ? 3 : 0 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1, fontSize: 17 }} id='informations-section'>
                            Informations
                        </Typography>
                        <Divider sx={{ marginBottom: 2 }} />
                        {/* {demande.id !== null && ( */}
                            <>
                                {renderField("Statut", demande.statut)}
                                {renderField("Date de ramassage", demande.ramassageDate ? dayjs(demande.ramassageDate).format('DD/MM/YYYY') : '')}
                                {renderField("Heure de ramassage", demande.ramassageTime)}
                                {renderField("Date de crémation", demande.cremaDate ? dayjs(demande.cremaDate).format('DD/MM/YYYY') : '')}
                            </>
                        {/* )} */}
                        {renderField("Date de création", new Date(demande.created).toLocaleDateString('fr-FR') + ' à ' + new Date(demande.created).toLocaleTimeString('fr-FR'))}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

