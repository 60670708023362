import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, updateDoc, addDoc, getDoc, deleteDoc, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Container, Typography, Grid, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, Box, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import { useAlert } from './AlertContext';
import { auth, db } from './firebase';
import { handleSendEmail } from './sendMail'; // Assurez-vous d'avoir une fonction pour envoyer des emails
import CloseIcon from '@mui/icons-material/Close';
import { localeText } from './config/localeText';

export const GetCommandes = (userRole, onUpdateCommandes) => {
    const [commandes, setCommandes] = useState([]);
    const [viewCommande, setViewCommande] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(true);
    const { showAlert } = useAlert();


    const fetchCommandes = () => {
        setLoading(true);
        const userUID = auth.currentUser.uid; // Récupérer l'UID de l'utilisateur connecté
        let commandesCollectionRef = collection(db, "commandes");

        // Appliquer un filtre si l'utilisateur n'est pas admin
        if (userRole.userRole !== 'admin') {
            commandesCollectionRef = query(commandesCollectionRef, where('userUID', '==', userUID));
        }

        const unsubscribe = onSnapshot(commandesCollectionRef, (snapshot) => {
            const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            // Trier les commandes par date sur la prop created
            data.sort((a, b) => new Date(b.created) - new Date(a.created));
            setCommandes(data);
            setLoading(false);
        }, (error) => {
            console.error("Erreur lors de la récupération des commandes:", error);
            setLoading(false);
        });

        return unsubscribe;
    };

    useEffect(() => {
        const unsubscribe = fetchCommandes();
        return () => unsubscribe && unsubscribe();
    }, []);

    const handleRowClick = (commande) => {
        setViewCommande(commande);
        setOpenAddModal(true);
    };

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setViewCommande(null);
    };

    const handleDeleteCommande = async (commande) => {
        try {
            await deleteDoc(doc(db, "commandes", commande.id));
            showAlert("Commande supprimée avec succès !", 'success');
        } catch (error) {
            console.error("Erreur lors de la suppression de la commande :", error);
            showAlert("Erreur lors de la suppression.", 'error');
        }
    };

    const columns = [
        { field: 'clinique', headerName: 'Clinique', flex: 1 },
        { field: 'fournitures', headerName: 'Fournitures', flex: 1 },
        {
            field: 'created', headerName: 'Date de commande', flex: 1, valueFormatter: (params) => {
                return params ? new Date(params).toLocaleDateString() : '';
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            renderCell: (params) => (
                <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteCommande(params.row); }}>
                    <DeleteIcon />
                </IconButton>
            )
        }
    ];

    return (
        <Container maxWidth="lg">
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Commandes</Typography>
            {isMobile ? (
                <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10,  overflow: 'auto' }}>
                    {commandes.map((commande) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={commande.id}>
                            <Card variant="outlined" sx={{ height: '100%', padding: 0, paddingBottom: 1.5 }} onClick={() => handleRowClick(commande)} style={{ cursor: 'pointer' }}>
                                <CardContent style={{ padding: 0 }}>
                                    <Typography variant="h6" align="center" gutterBottom paddingTop={1} fontWeight={600} fontSize={"100%"}>
                                        {commande.fournitures.join(', ')}
                                    </Typography>
                                    <Typography variant="h6" align="center" gutterBottom paddingTop={1} fontWeight={600} fontSize={"100%"}>
                                        {commande.clinique}
                                    </Typography>
                                    <Typography variant="body1" align="center" gutterBottom paddingTop={1} fontWeight={200} fontSize={"80%"}>
                                        {'Créé le ' + new Date(commande.created).toLocaleDateString()}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <><IconButton
                    color="primary"
                    aria-label="add"
                    onClick={() => setOpenAddModal(true)}
                // sx={{ position: 'fixed', bottom: 16, right: 16 }}
                >
                    <AddIcon />
                </IconButton>
                    <DataGrid
                        rows={commandes}
                        loading={loading}
                        localeText={localeText}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        onRowClick={(params) => handleRowClick(params.row)}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#7cb1e2', // Couleur de fond pour les en-têtes de colonnes
                            },
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: '#7cb1e2', // Couleur de fond pour chaque en-tête de colonne
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                color: 'white', // Couleur du texte pour les en-têtes de colonnes
                            },
                            '& .MuiDataGrid-row:nth-of-type(odd)': {
                                backgroundColor: '#ffffff', // Couleur de fond pour les lignes paires
                            },
                            '& .MuiDataGrid-row:nth-of-type(even)': {
                                backgroundColor: '#7cb1e2', // Couleur de fond pour les lignes impaires
                                color: 'white',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-cell': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-row.Mui-selected': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-row:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        }}
                    />
                </>
            )}
            <AddCommande commandeProp={viewCommande} userRole={userRole} onClose={handleCloseAddModal} open={openAddModal} onUpdateCommandes={onUpdateCommandes} />
        </Container>
    );
};

export const AddCommande = ({ commandeProp, userRole, onClose, open, onUpdateCommandes }) => {
    const [commande, setCommande] = useState({
        fournitures: [],
        id: null,
        clinique: '', // Champ pour la clinique
    });
    const [userEmail, setUserEmail] = useState('');
    const [fournituresList, setFournituresList] = useState([]);
    const { showAlert } = useAlert();

    useEffect(() => {
        if (commandeProp) {
            setCommande(commandeProp);
        } else {
            setCommande({ fournitures: [], id: null, clinique: '' });
        }
    }, [commandeProp]);

    useEffect(() => {
        const fetchFournitures = () => {
            const fournituresCollectionRef = collection(db, "fournitures");
            const unsubscribe = onSnapshot(fournituresCollectionRef, (snapshot) => {
                const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setFournituresList(data);
            }, (error) => {
                console.error("Erreur lors de la récupération des fournitures:", error);
            });

            return unsubscribe;
        };

        const fetchVetInfo = async () => {
            try {
                const userUID = auth.currentUser.uid;

                const userDocRef = doc(db, "veto", userUID);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();

                    setCommande(prev => ({
                        ...prev,
                        clinique: userData.clinique || '', // Assurez-vous que le champ 'clinique' existe
                        userUID: userUID,
                        created: new Date().toISOString()
                    }));
                    setUserEmail(userData.email);
                } else {
                    console.warn("Aucun document trouvé pour cet utilisateur.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des informations de l'utilisateur :", error);
            }
        };
        fetchVetInfo();
        const unsubscribe = fetchFournitures();
        return () => unsubscribe && unsubscribe();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCommande(prev => ({
            ...prev,
            [name]: Array.isArray(value) ? value : value
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const commandeData = {
                ...commande,
                userUID: commande.userUID, // Utiliser le UID récupéré
            };

            if (commande.id) {
                await updateDoc(doc(db, "commandes", commande.id), commandeData);
                showAlert("Commande mise à jour avec succès !", 'success');
            } else {
                const { id, ...data } = commandeData;
                await addDoc(collection(db, "commandes"), data);
                showAlert("Nouvelle commande créée avec succès !", 'success');
            }
            // onUpdateCommandes();
            onClose();

            const emailContent = `Commande de fournitures: ${commande.fournitures.join(', ')} pour la clinique: ${commande.clinique}`;
            const send = await handleSendEmail(userEmail, 'accueil@crematorium-animalierdepicardie.fr', 'Nouvelle Commande de fournitures', emailContent);
            if (send.message === "Email sent successfully") {
                showAlert("Email envoyé avec succès !", 'success');
            } else {
                showAlert("Erreur lors de l'envoi de l'email !", 'error');
            }
        } catch (error) {
            console.error("Erreur lors de la soumission de la commande :", error);
            showAlert("Erreur lors de la soumission.", 'error');
        }
    };


    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{commande.id ? "Modifier la commande" : "Nouvelle commande"}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Container>
                    <Box component="form" onSubmit={handleFormSubmit}>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel>Fourniture</InputLabel>
                                <Select
                                    name="fournitures"
                                    value={commande.fournitures}
                                    onChange={handleChange}
                                    required
                                    multiple
                                    label="Fournitures"
                                >
                                    {fournituresList.map(fourniture => (
                                        <MenuItem key={fourniture.id} value={fourniture.title}>
                                            {fourniture.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <Button
                                    type='submit'
                                    variant="contained"
                                    color="success"
                                    startIcon={<SaveIcon sx={{ fontSize: "2rem" }} />}
                                    sx={{ fontSize: "1rem", padding: "10px 20px" }}
                                >
                                    Enregistrer
                                </Button>
                            </FormControl>
                        </Grid>
                    </Box>
                </Container>
            </DialogContent>
        </Dialog>
    );
};
