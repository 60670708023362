import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  // Votre configuration Firebase
  apiKey: "AIzaSyDFJ8bHpc4DJ6XOUShjBeZ6_ufNZG4zbZc",
  authDomain: "crematorium-493de.firebaseapp.com",
  projectId: "crematorium-493de",
  storageBucket: "crematorium-493de.appspot.com",
  messagingSenderId: "60199111032",
  appId: "1:60199111032:web:65fc4e184aed9894dc90f3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Auth
const auth = getAuth(app);

// Initialize Firebase Storage
const storage = getStorage(app);

export { auth, db, storage };
