import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HistoryIcon from '@mui/icons-material/History';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './App.css';
import LoginForm from './login';
import { db, auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Users from './users';
import { AddDemandes, GetDemandes } from './demandes';
import { Check } from './check';
import { AlertProvider, useAlert } from './AlertContext';
import { Fab, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Accueil from './accueil';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { doc, getDoc } from 'firebase/firestore';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from './img/logo1.png';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DescriptionIcon from '@mui/icons-material/Description';
import EuroIcon from '@mui/icons-material/Euro';
import CalculateIcon from '@mui/icons-material/Calculate';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PeopleIcon from '@mui/icons-material/People';
import { AddFournitures, GetFournitures } from './fournitures';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { GetCommandes } from './commander';
import InventoryIcon from '@mui/icons-material/Inventory';
import { GetInvoices } from './invoices';
const drawerWidth = 250;

const navigationItems = [
  { label: "Accueil", path: "/", icon: <HomeIcon /> },
  { label: "Nouvelle demande", path: "/", icon: <AssignmentIcon />, action: 'openModal' },
  { label: "Demandes", path: "/getDemandes", icon: <HistoryIcon /> },
  { label: "Fournitures", path: "/getCommandes", icon: <ShoppingCartIcon /> },
  { label: "Factures", path: "/getInvoices", icon: <EuroIcon /> },
  { label: "Ramassage", path: "/check", icon: <QrCodeScannerIcon />, userRole: "admin" },
  { label: "Utilisateurs", path: "/users", icon: <PersonIcon />, userRole: "admin" },
  { label: "Gérer les fournitures", path: "/getFournitures", icon: <InventoryIcon />, userRole: "admin" },
];
// function MainComponent() {
//   const { showAlert } = useAlert();

//   const handleClick = () => {
//     showAlert('Ceci est un message d\'alerte', 'success');
//   };

//   return (
//     <div>
//       <button onClick={handleClick}>Afficher l'alerte</button>
//     </div>
//   );
// }

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [userClinique, setUserClinique] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(isMobile);
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState(0);
  const getDemandesRef = useRef(null);
  const [loading, setLoading] = useState(true);
  // const { alert, closeAlert } = useAlert();
  const [isFullScreenIOS, setIsFullScreenIOS] = useState(false);

  const open = Boolean(anchorEl);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setIsLoggedIn(true);
        setLoading(false);
        user.getIdTokenResult().then(async token => {
          setUserToken(token.token);
          setUserRole(token.claims.admin ? 'admin' : 'veto');
          const userRef = doc(db, "veto", user.uid);
          const docSnap = await getDoc(userRef);
          if (docSnap) {
            const userData = docSnap.data();
            setUserClinique(userData.clinique);
            setUserEmail(userData.email);
          } else {
            console.log("No such user in Firestore!");
          }
        });
      } else {
        setIsLoggedIn(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    function detectFullScreenIOS() {
      const isInStandaloneMode = window.navigator.standalone;
      setIsFullScreenIOS(isInStandaloneMode);
    }

    detectFullScreenIOS();
    window.addEventListener('resize', detectFullScreenIOS);

    return () => {
      window.removeEventListener('resize', detectFullScreenIOS);
    };
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.signOut();
    setIsLoggedIn(false);
    handleClose();
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleOpenModal = (event) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleUpdateDemandes = async () => {
    if (getDemandesRef.current) {
      await getDemandesRef.current.fetchDemandes();
    }
  };

  // const AlertSnackbar = () => {
  //   const { alert, closeAlert } = useAlert();

  //   return (
  //     <Snackbar
  //       open={alert.open}
  //       autoHideDuration={6000}
  //       onClose={closeAlert}
  //     >
  //       <Alert onClose={closeAlert} severity={alert.severity} sx={{ width: '100%' }}>
  //         {alert.message}
  //       </Alert>
  //     </Snackbar>
  //   );
  // };

  if (!isLoggedIn && !loading) {
    return <LoginForm />;
  }
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={80} />
      </div>
    );
  }

  return (
    <AlertProvider>
      <Router>
        <div style={{ display: 'flex', height: '100vh' }}>
          <CssBaseline />

          {/* {!isMobile && ( */}
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              <img src={logo} alt="My Image" height={"60px"} />
              {!isMobile && (
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
                  Crématorium Animalier de Picardie
                </Typography>
              )}
              {/* <div style={{ marginLeft: 'auto' }}>
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </div> */}
              <Typography variant="body1" sx={{ marginRight: 2, display: isMobile ? "none" : null }}>
                {"Urgence vétérinaire : 06 83 77 89 27"}
              </Typography>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                onClick={handleMenu}
                sx={{ ml: 'auto', mr: 2 }}
              >
                <AccountCircleIcon />
              </IconButton>
              <Typography variant="body1" sx={{ marginRight: 2, display: isMobile ? "none" : null }}>
                {userClinique}
              </Typography>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Mon compte</MenuItem>
                <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
          {/* )} */}
          {!isMobile && (
            <Drawer
              variant="permanent"
              sx={{
                width: !mobileMenuOpen ? drawerWidth : "50px",
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: !mobileMenuOpen ? drawerWidth : "50px", boxSizing: 'border-box', top: '64px' }
              }}
            >
              <List>
                {navigationItems.map(item => {
                  if (item.userRole === "admin" && userRole !== 'admin') {
                    return null;
                  }
                  return (
                    <ListItem key={item.label} disablePadding>
                      <ListItemButton
                        component={item.action === 'openModal' ? 'button' : Link}
                        to={item.path}
                        onClick={item.action === 'openModal' ? handleOpenModal : null}
                      >
                        <ListItemIcon>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={!mobileMenuOpen ? item.label : null} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              <div style={{ marginTop: 'auto', paddingBottom: 80, paddingRight: 0.2 }}>
                <Typography variant="body1" sx={{ marginLeft: 2 }}>
                  {"380 rue du Séhu, ZA la Hayette"}<br />
                  {"80290 Poix-de-Picardie"}<br />
                  {"Tél : 03 22 69 51 16"}
                </Typography>
              </div>
            </Drawer>
          )}
          {isMobile && (
            <div style={{
              position: 'fixed',
              // bottom: isFullScreenIOS ? '20px' : 0,
              bottom: 0,
              left: 0,
              right: 0,
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              display: 'flex',
              width: '100%',
              height: isFullScreenIOS ? 80 : 70,
              zIndex: 10,
              backgroundColor: '#f5f5f5',
              paddingBottom: isFullScreenIOS ? 'env(safe-area-inset-bottom)' : 0
            }}>
              <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
                style={{
                  display: 'flex',
                  width: 'max-content',
                  paddingBottom: 0,
                  // paddingBottom: 20,
                  backgroundColor: 'inherit', // Hérite du fond blanc du parent
                  boxShadow: '0px -2px 4px rgba(0,0,0,0.1)'
                }}
              >
                {navigationItems.map(item => {
                  if (item.userRole === "admin" && userRole !== 'admin' || item.label == "Nouvelle demande") {
                    return null;
                  }
                  return (
                    <BottomNavigationAction
                      key={item.label}
                      label={item.label}
                      icon={item.icon}
                      component={Link}
                      to={item.path}
                      onClick={item.action === 'openModal' ? handleOpenModal : null}
                    />
                  );
                })}
              </BottomNavigation>
            </div>

          )}
          {isMobile && (
            <Fab
              color="primary"
              aria-label="add"
              onClick={handleOpenModal} // Assurez-vous que cette fonction ouvre le modal pour "Nouvelle demande"
              sx={{
                position: 'fixed',
                bottom: 80,
                right: 30,
                backgroundColor: "#1976D2"
              }}
            >
              <AddIcon />
            </Fab>
          )}
          <main style={{ flexGrow: 1, padding: '24px', marginTop: '64px', marginBottom: isMobile ? '156px' : '0' }}>
            <Routes>
              <Route path="/" element={<Accueil userRole={userRole} userEmail={userEmail} />} />
              {userRole === "admin" && <Route path="/users" element={<Users />} />}
              <Route path="/" element={<AddDemandes isEditMode={true} userRole={userRole} onUpdateDemandes={handleUpdateDemandes} />} />
              <Route path="/getDemandes" element={<GetDemandes userRole={userRole} />} />
              {userRole === "admin" && <Route path="/check" element={<Check userEmail={userEmail} />} />}
              {userRole === "admin" && <Route path="/getFournitures" element={<GetFournitures userRole={userRole} />} />}
              <Route path="/getCommandes" element={<GetCommandes userRole={userRole} />} />
              <Route path="/getInvoices" element={<GetInvoices userRole={userRole} />} />
            </Routes>
          </main>
          <AddDemandes isEditMode={true} userRole={userRole} open={modalOpen} onClose={handleCloseModal} userEmail={userEmail} />
        </div>
      </Router>
    </AlertProvider>
  );
}

export default App;
